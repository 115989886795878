import { Injectable } from '@angular/core';


@Injectable()
export class SessionStorageService  {

  constructor () {}
      /**
     * @ngdoc method
     * @name get
     * @methodOf grips_spg_2.AppServices.SessionService
     *
     * @param {string} key The key for the item in sessionStorage.
     *
     * @description
     * get an item from sessionStorage, so far, this is only used to store currentUser.
     *
     * @returns {Object} currentUser
     */
    get (key: string) {
      return sessionStorage.getItem(key);
    }


    getObject(key: string) {
      return JSON.parse(sessionStorage.getItem(key));
    }

    /**
   * @ngdoc method
   * @name set
   * @methodOf grips_spg_2.AppServices.SessionService
   *
   * @param {string} key A key for the item in sessionStorage.
   * @param {string} val The value to be stored to 'key'.
   *
   * @description
   * Set a key, value pair in sessionStorage. So far, this is only used to store currentUser.
   */
  set(key: string, val: any) {
      return sessionStorage.setItem(key, val);
  }

  setObject(key: string, val: any){
    return sessionStorage.setItem(key, JSON.stringify(val));

  }
  /**
   * @ngdoc method
   * @name unset
   * @methodOf grips_spg_2.AppServices.SessionService
   *
   * @param {string} key A key for the item in sessionStorage to be removed
   *
   * @description
   * Remove an item from sessionStorage. So far, this is only used to store currentUser.
   */
  unset(key: string) {
      return sessionStorage.removeItem(key);
  } 
}

