  import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import {  throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import CustomStore from 'devextreme/data/custom_store';

import mainMatJson from "./json/mainmaterial.json" 
import subMatJson from "./json/submaterial.json"
import formatJson from "./json/format.json"
import limitsJson from "./json/limitations.json"
import formatGroupJson from "./json/formatgroup.json"

export class Item {
  ID: number;
  Name: string;
  Category: string;
  Category_ID: number;
  Parent_ID?: number;
  Image?: string;
  Infrastructure?: string;
  BaseScore?: number;
  TreeParent_ID: number;
}


const mainMaterialItems: Item[]=mainMatJson;

const subMaterialItems: Item[] = subMatJson;

const packFormatItems: Item[] = formatJson;
const limitItems: Item[] = limitsJson;

const formatGroupItems: Item[] = formatGroupJson;

export class AuditResponse {

  Reference: string;
  MainMaterial?: Item;
  SubMaterial?: Item;
  PackagingFormat?: Item;
  Limititations?: Item[];
  
}


@Injectable({
  providedIn: 'root'
})
export class AuditService {

  customDataSource: CustomStore;
  categoryDataStore: CustomStore;

  //private apiServer = "http://localhost:8010/proxy/company_audits";
  private apiServer = "https://devspg.greenstreets.ie/";
  //private apiServer = "https://localhost/devspg/";
private auditsVerb = "company_audits";
  private responsesVerb ="audit_question_responses/all_company_responses_to_audit_questions";

  constructor(private httpClient: HttpClient) { }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    
    }),
    params: null
  }

  getMainMaterials(): Item[]{
    return mainMaterialItems;
  }

  getSubMaterials(): Item[]{
    return subMaterialItems;
  }

  getPackFormats(): Item[]{
    return packFormatItems;
  }

  getLimitations(): Item[]{
    return limitItems;
  }

  getComplianceTree(): Item[]{

    var treeData: Item[];
    

    treeData= mainMaterialItems.concat(formatGroupItems);
    treeData= treeData.concat(packFormatItems);
    treeData = treeData.concat(subMaterialItems);
    treeData = treeData.concat(limitItems);

    return treeData;


  }

  //gets all audits of a particular type
  getAudits (auditType): Observable<any> {

    const params = new HttpParams().append('category_type_id', auditType);

    this.httpOptions.params = params;

    console.log('Audit Service getAudits...');
    console.log(JSON.stringify(auditType));
  //  return this.httpClient.get<any>(this.apiServer+this.auditsVerb ,  this.httpOptions)
    return this.httpClient.get<any>(this.apiServer+this.auditsVerb +"?category_type_id="+auditType)
  .pipe(
      catchError(this.errorHandler))
  }



  //gets an audit by id
  get (id: number): Observable<any> {


    console.log('Audit Service get audit id='+id);
    return this.httpClient.get<any>(this.apiServer+this.auditsVerb+'/'+id ,  this.httpOptions)
    .pipe(
      catchError(this.errorHandler))
  }


  //gets audits of a particular audittype in the form of customDataSource
  getDataSource(auditType) {
    this.customDataSource = new CustomStore({
      key: 'id',
      load: () => {
          return this.httpClient.get(this.apiServer +this.auditsVerb + '?category_type_id=' + auditType)
              .toPromise()
              .catch(() => { throw 'Data loading error' });
      }
    });
    return this.customDataSource;
  }

  getResponsesDataSource(audit_id) {
    this.customDataSource = new CustomStore({
      key: 'response_id',
      load: () => {
          return this.httpClient.get(this.apiServer + this.responsesVerb +'/' + audit_id)
              .toPromise()
              .catch(() => { throw 'Data loading error' });
      } 
    });
    return this.customDataSource;
  }

  getCategoryTypes(){

    
    console.log('Audit Service getCategoryTypes');
    console.log(this.apiServer + this.auditsVerb +'/category_types_index/');
    
    this.categoryDataStore = new CustomStore({
      key: "id",
      loadMode: "raw",
      load: () => {
        return this.httpClient.get(this.apiServer + this.auditsVerb +'/category_types_index?category_type_id=9')
            .toPromise()
            .then((data: any) => {
              return data;
            })
            .catch(() => { throw 'Data loading error' });
 
            
          } 
   
 
    });

    return this.categoryDataStore;

  }


  errorHandler(error) {
    let errorMessage = '';
    if(error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
 }

}