<h2 class="content-block">Home</h2>
<div class="content-block" >
  <div class="dx-card responsive-paddings">
  <div class="logos-container">
   <!-- <p><img src="/app/webroot/dist/gripsrecylx/assets/img/grips-logo.png"/></p> -->
    <p><img src="/app/webroot/dist/gripsrecylx/assets/img/login-page.jpg"/></p>
  </div>

  
</div>
</div>
