import { Component, ViewChild } from '@angular/core';
import {  OnInit, ComponentFactoryResolver } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuditService } from '../../shared/services/audit.service'
import { Item,AuditResponse } from '../../shared/services/audit.service'
import {Router, ActivatedRoute} from '@angular/router';
import { DxFormComponent} from 'devextreme-angular';
import {ResponseMemberComponent} from './response-item-container.component'
import {ResponseItemComponent} from './response-item.component'
import { ThrowStmt } from '@angular/compiler';
import { FormsModule,NgForm} from '@angular/forms';
import DataSource from 'devextreme/data/data_source';
import { DxListModule, DxTemplateModule,DxListComponent,DxLinearGaugeModule } from 'devextreme-angular';
//import { ArchwizardModule } from 'angular-archwizard';

@Component({
  templateUrl: 'audit-detail.component.html',
  styleUrls: [ './audit-detail.component.scss' ],
  providers: [AuditService]
})

export class AuditDetailComponent implements OnInit {

  @ViewChild(DxListComponent, { static: false }) list: DxListComponent;

  public readonly imageRoot = '/app/webroot/dist/gripsrecylx/assets/img/'

   audit_id: any;
  
    auditResponse: AuditResponse;
    dataSource: any = [];
    audit: any;
    response: any;
    responseOptions: any=[];
    responseFormData: any=[];    
    formItems:  any = [];
    collapsed = false;
    colCountByScreen: any;
    mainMatdata: Item[];
    subMatdata: Item[];
    packFormatdata: Item[];
    limitationsData: Item[];
    mainMaterialSource: DataSource;
    subMaterialSource: DataSource;
    packFormatSource: DataSource;
    limitationSource: DataSource;


    refInput: string ='';
    assessed: boolean = false;
    assessedRecyclable: boolean = false;
    assessedNonRecyclable: boolean = false;
    recyclableTextArr: string[] = ['>90% Recyclable','Limited Recyclability(<50%)','Non-Recyclable(<10%)'];
    buttonTypeArr: string[] = ['Success','Warning','Danger']

    buttonType: string ='';
    recyclableText: string='';
    recyclabilityScore: number=0;
    recyclabilityColor: string='';
  
    onSelectionChanged(e){

      console.log(this.list.instance.option("selectedItems"));

      this.auditResponse.Limititations = this.list.instance.option("selectedItems");
      
    }

    onValueChanged(e){
      console.log(e.element.id);
      console.log(e.value);

//      const item = this.data.filter(i => i.ID === e.value)[0];

      if (e.element.id==='mainMaterial'){
        const item = this.mainMatdata.filter(i => i.ID === e.value)[0];
        console.log(item.ID + ": " + item.Name);
        this.auditResponse.MainMaterial = item; 
        this.packFormatdata= this.packFormatdata.filter(i=>i.Parent_ID === item.Category_ID);
        this.createPackFormatStore();
      } 
      
      if(e.element.id==='packagingFormat') {
        const item = this.packFormatdata.filter(i => i.ID === e.value)[0];
        console.log(`${item.ID}: ${item.Name}:  ${item.Category_ID}`);
       this.auditResponse.PackagingFormat = item;
        this.subMatdata= this.subMatdata.filter(i=>i.Category_ID === item.ID);
        console.log(this.subMatdata);
        this.createSubMainStore();

      }

      if(e.element.id==='subMaterial') {
        const item = this.subMatdata.filter(i => i.ID === e.value)[0];
        console.log(`${item.ID}: ${item.Name}:  ${item.Category_ID}`);
       this.auditResponse.SubMaterial = item;
       this.limitationsData = this.limitationsData.filter(i=>i.Parent_ID === item.ID);
       this.createLimitationStore();
       console.log(this.limitationsData);
       console.log(this.limitationSource);
      }

    }

    finalizeReset(): void {

      this.router.navigate(['/audit',this.audit_id]);
      this.ngOnInit();

    }

    finishFunction(): void{

      this.recyclabilityScore = 0;

      console.log('=============finishFunction');

      console.log(this.auditResponse);

      console.log(this.auditResponse.SubMaterial.Infrastructure);

      this.recyclabilityScore = this.auditResponse.SubMaterial.BaseScore;

      if(this.auditResponse.Limititations){  
        for (let i = 0; i < this.auditResponse.Limititations.length; i++) {
          this.recyclabilityScore += this.auditResponse.Limititations[i].BaseScore;
        }
      }

      console.log("Current Score: "+this.recyclabilityScore);

      switch(true) {

            case (this.recyclabilityScore >= 91):
              //good recycling infrastructure
            this.setRecyclableA();
              break;
            
            case (this.recyclabilityScore >= 51 ):
                this.setRecyclableB();
            //possibly recyclable
              break;
            
            default:
              this.setRecyclableC();
              //not recyclable
              if (this.recyclabilityScore<0) {
                  this.recyclabilityScore=0;
              }
              break;

      }


      console.log (JSON.stringify(this.auditResponse));

      this.assessed=true;
    }

    setRecyclableA():void {
      this.buttonType = this.buttonTypeArr[0];        
      this.recyclableText = this.recyclableTextArr[0];
      this.assessedNonRecyclable=false;
      this.assessedRecyclable=true;
      this.recyclabilityColor="#77DD77";      

    }

    setRecyclableB():void {
      this.buttonType = this.buttonTypeArr[1];        
      this.recyclableText = this.recyclableTextArr[1];
      this.assessedNonRecyclable=true;
      this.assessedRecyclable=false;
      this.recyclabilityColor="#E6E200";      

    }


    setRecyclableC():void {
      this.buttonType = this.buttonTypeArr[2];        
      this.recyclableText = this.recyclableTextArr[2];
      this.assessedNonRecyclable=true;
      this.assessedRecyclable=false;
      this.recyclabilityColor="#77DD77";      

    }


   canExitStep1() : boolean {

      //  console.log('reference length: '+ this.auditResponse.Reference.length);
      console.log('canExit Step 1');
      const isExit = this.refInput.length>0

  //    const isExit = this.auditResponse.Reference && this.auditResponse.Reference.length>0
      console.log ('isExit:'+isExit);
        return isExit;

   }

    constructor(private service: AuditService, public router: Router, private actRoute: ActivatedRoute ) {


    
    }


    onKey(newValue): void{


      this.refInput = newValue;
      this.auditResponse.Reference=newValue;
      this.canExitStep1();
    }

    ngOnInit() {

        this.refInput='';
        this.assessedNonRecyclable=false;
        this.assessedRecyclable=false;      
        this.recyclabilityScore = 0;
        this.assessed=false;

        this.auditResponse = new AuditResponse();

          console.log('ngOnInit - auditDetailcomponent.ts');
          this.audit_id = this.actRoute.snapshot.params.id;

        // this.auditResponse.Audit_ID=this.audit_id;

          this.service.get (this.audit_id)
          .subscribe(
            (response) => {console.log(response);
                          this.audit = response;

            },
            (error) => console.log(error)
          );

          this.mainMatdata = this.service.getMainMaterials();
          this.mainMaterialSource = new DataSource({
              store: {
                  data: this.mainMatdata,
                  type: 'array',
                  key: 'ID'
              }
          });

          this.auditResponse = {Reference: '', MainMaterial:this.mainMatdata[0]};

          this.subMatdata= this.service.getSubMaterials();
          this.createSubMainStore();
          this.auditResponse.SubMaterial=this.subMatdata[0];


          this.packFormatdata= this.service.getPackFormats();
          this.createPackFormatStore();
          this.auditResponse.PackagingFormat=this.packFormatdata[0];

          this.auditResponse.SubMaterial=this.packFormatdata[0];

          this.limitationsData=this.service.getLimitations();


          //this.auditResponse.Audit_ID = this.audit_id;

          this.dataSource = this.service.getResponsesDataSource(this.audit_id);
      
        
          
          this.colCountByScreen = {
            xs: 1,
            sm: 2,
            md: 3,
            lg: 4
          };
      }

    createSubMainStore() :void {

      this.subMaterialSource = new DataSource({
        store: {
            data: this.subMatdata,
            type: 'array',
            key: 'ID'
        }
    });
    }

   createPackFormatStore() : void {

    this.packFormatSource = new DataSource({
      store: {
          data: this.packFormatdata,
          type: 'array',
          key: 'ID'
      },
      group: "Category"
    });

   }    

   createLimitationStore() :void {

    this.limitationSource = new DataSource({
      store: {
          data: this.limitationsData,
          type: 'array',
          key: 'ID'
      }
  });
  }




}
