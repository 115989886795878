import { Component, Input } from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import { DxFormComponent} from 'devextreme-angular';

@Component({
  selector: 'app-response-item-detail',
  templateUrl: 'response-item.component.html',
  styleUrls: ['response-item.component.scss']
})
export class ResponseItemComponent {
  @Input() responseItem: any;

  constructor() { 
    console.log('responseitemcomponent.ts');
  }
}