<h2 class="content-block">Recyclability Determination Model</h2>

<dx-tree-list
    id="recyclabilityTree"
    [dataSource]="treeData"
    keyExpr="ID"
    parentIdExpr="TreeParent_ID"
    [columnAutoWidth]="true"
    [wordWrapEnabled]="true"
    [showBorders]="true">

    <dxo-search-panel [visible]="true" width="250"></dxo-search-panel>
    <dxo-header-filter [visible]="true"></dxo-header-filter>
    <dxo-selection mode="multiple"></dxo-selection>
    <dxo-column-chooser [enabled]="true"></dxo-column-chooser>

    <dxi-column dataField="Name" [minWidth]="500"></dxi-column>
    <dxi-column dataField="Category" caption="Category" [width]="200"></dxi-column>
    <dxi-column dataField="Infrastructure" caption="Infrastructure"[width]="100"></dxi-column>
    <dxi-column dataField="BaseScore" caption="BaseScore" [width]="100"></dxi-column>
    
</dx-tree-list>
