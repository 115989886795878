import { Component, HostBinding, Inject,Injectable } from '@angular/core';
import { AuthService, ScreenService, AppInfoService, AuditService } from './shared/services';
import { APP_BASE_HREF } from "@angular/common";
import { DxDataGridModule, DxFormComponent,
  DxBulletModule,DxButtonComponent,
  DxTemplateModule } from 'devextreme-angular';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

@Injectable({
  providedIn: 'root'
})
export class AppComponent  {
  @HostBinding('class') get getClass() {
    return Object.keys(this.screen.sizes).filter(cl => this.screen.sizes[cl]).join(' ');
  }

  constructor(private authService: AuthService, private screen: ScreenService, public appInfo: AppInfoService, @Inject(APP_BASE_HREF) private baseHref: string, public auditService: AuditService) { }

  isAutorized() {
    return this.authService.loggedIn;
  }
}
