import { Component } from '@angular/core';
import { DxDataGridModule,
  DxBulletModule,
  DxTemplateModule,DxSelectBoxModule, DxButtonModule } from 'devextreme-angular';
import DataSource from 'devextreme/data/data_source';
import { AuditService } from '../../shared/services/audit.service'
import {Router} from '@angular/router';


@Component({
  templateUrl: 'audits.component.html',
  providers: [AuditService]
})

export class AuditsComponent {


    dataSource: any;
    categoriesData: any;
    collapsed = false;  
    refreshModes: string[];
    refreshMode: string;
    contentReady = (e) => {
        if(!this.collapsed) {
            this.collapsed = true;
          //  e.component.expandRow(["EnviroCare"]);
        }
    };

    rowClicked = (e) => {
        console.log("============row clicked");
        console.log(e);

        this.navigateTo('/audit',e.data.id);
    };

    cellDblClicked = (e) => {
      console.log("============cell double clicked");
      console.log(e);

      this.navigateTo('/audit',e.row.data.id);
  };

    constructor(service: AuditService, private router: Router) {
        this.dataSource = service.getDataSource(9);
        console.log('calling service.getCategoryTypes');
        this.categoriesData = service.getCategoryTypes();
    }

    navigateTo  (url: string,id: any){

      this.router.navigate([url,id]);
    }

}
