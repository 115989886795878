import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { LoginFormComponent, ResetPasswordFormComponent, CreateAccountFormComponent, ChangePasswordFormComponent } from './shared/components';
import { AuthGuardService } from './shared/services';
import { HomeComponent } from './pages/home/home.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { TasksComponent } from './pages/tasks/tasks.component';
import { AuditsComponent } from './pages/audits/audits.component';
import { AuditTreeComponent } from './pages/audits/audit-tree.component';
import { AuditDetailComponent } from './pages/audits/audit-detail.component';
import { ResponseMemberComponent } from './pages/audits/response-item-container.component';
import { ResponseItemComponent } from './pages/audits/response-item.component';
import { ReportsComponent } from './pages/reports/reports.component';


import { DxDataGridModule, DxFormModule,DxBulletModule,DxButtonModule,
  DxTemplateModule,
  DxFormComponent,DxTextBoxModule,
  DxSelectBoxModule,DxListModule,DxTreeListModule,DxLinearGaugeModule
 } from 'devextreme-angular';
 import { ArchwizardModule } from 'angular-archwizard';

const routes: Routes = [
  {
    path: 'tasks',
    component: TasksComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'audits',
    component: AuditsComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'audittree',
    component: AuditTreeComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'audit/:id',
    component: AuditDetailComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'reports',
    component: ReportsComponent,
    canActivate: [ AuthGuardService ]
  },

  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'login-form',
    component: LoginFormComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'reset-password',
    component: ResetPasswordFormComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'create-account',
    component: CreateAccountFormComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'change-password/:recoveryCode',
    component: ChangePasswordFormComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: '**',
    redirectTo: 'home',
    canActivate: [ AuthGuardService ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes), DxDataGridModule, DxFormModule,DxButtonModule,DxTextBoxModule,
            CommonModule,FormsModule,ArchwizardModule,DxSelectBoxModule,DxListModule,DxTreeListModule,DxLinearGaugeModule],
  providers: [AuthGuardService],
  exports: [RouterModule],
  declarations: [HomeComponent, ProfileComponent, TasksComponent,AuditsComponent,AuditDetailComponent,AuditTreeComponent,
    AuditDetailComponent,ResponseMemberComponent,ResponseItemComponent,ReportsComponent]
})
export class AppRoutingModule { }
