import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AppComponent } from './app.component';
import { SideNavOuterToolbarModule, SideNavInnerToolbarModule, SingleCardModule } from './layouts';
import { FooterModule, LoginFormModule } from './shared/components';
import { AuthService, ScreenService, AppInfoService,AuditService } from './shared/services';
import { NotAuthorizedContainerModule } from './not-authorized-container';
import { AppRoutingModule } from './app-routing.module';
import { ArchwizardModule } from 'angular-archwizard';



import { AuditDetailComponent } from './pages/audits/audit-detail.component';
import { ResponseItemComponent } from './pages/audits/response-item.component';

import { DxDataGridModule, DxFormModule,DxButtonModule,
  DxBulletModule,DxSelectBoxModule,DxTextBoxModule,DxListModule,
  DxTemplateModule,DxLinearGaugeModule,
  DxTreeListModule} from 'devextreme-angular';

import { HttpClientModule } from '@angular/common/http';
import {APP_BASE_HREF, PlatformLocation } from '@angular/common';
import { FormsModule, NgForm } from '@angular/forms';



/**
 * This function is used internal to get a string instance of the `<base href="" />` value from `index.html`.
 * This is an exported function, instead of a private function or inline lambda, to prevent this error:
 *
 * `Error encountered resolving symbol values statically.`
 * `Function calls are not supported.`
 * `Consider replacing the function or lambda with a reference to an exported function.`
 *
 * @param platformLocation an Angular service used to interact with a browser's URL
 * @return a string instance of the `<base href="" />` value from `index.html`
 */
export function getBaseHref(platformLocation: PlatformLocation): string {
  return platformLocation.getBaseHrefFromDOM();
}


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
    SideNavOuterToolbarModule,
    SideNavInnerToolbarModule,
    SingleCardModule,
    FooterModule,
    LoginFormModule,
    NotAuthorizedContainerModule,
    AppRoutingModule,
    HttpClientModule,
    DxDataGridModule,
    DxButtonModule,
    DxFormModule,
    DxSelectBoxModule,
    DxListModule,
    DxTreeListModule,
    DxLinearGaugeModule,
    FormsModule,
    ArchwizardModule

    
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA ],
  entryComponents: [
    ResponseItemComponent
  ],
  providers: [AuthService, ScreenService, AppInfoService,AuditService,
              {provide: APP_BASE_HREF, useFactory: getBaseHref,deps: [PlatformLocation]},
              { provide: Window, useValue: window }],
  bootstrap: [AppComponent]
})
export class AppModule { }
