import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { DxDataGridModule,
  DxBulletModule,
  DxTemplateModule,DxSelectBoxModule, DxButtonModule } from 'devextreme-angular';
import DataSource from 'devextreme/data/data_source';
import { AuditService } from '../../shared/services/audit.service'
import {Router} from '@angular/router';

import * as pbi from 'powerbi-client';
declare var powerbi: any;

@Component({
  templateUrl: 'reports.component.html',
  styleUrls: [ './reports.component.scss' ],
  providers: [AuditService]

})

export class ReportsComponent implements OnInit {

  @ViewChild('embeddedReport') embeddedReport: ElementRef;
  config: any;


  public screenHeight:number;  
  @ViewChild('reportContainer') reportContainer: ElementRef;  


    dataSource: any;
    categoriesData: any;
    collapsed = false;  
    refreshModes: string[];
    refreshMode: string;
    contentReady = (e) => {
        if(!this.collapsed) {
            this.collapsed = true;
          //  e.component.expandRow(["EnviroCare"]);
        }
    };

    rowClicked = (e) => {
        console.log("============row clicked");
        console.log(e);

        this.navigateTo('/audit',e.data.id);
    };

    cellDblClicked = (e) => {
      console.log("============cell double clicked");
      console.log(e);

      this.navigateTo('/audit',e.row.data.id);
  };


  
  showReport(test: any) {  
    // Embed URL    

    this.service.getAudits (9)
    .subscribe(
      (response) => 
      {   console.log(response);
          this.dataSource = response;

          let accessConfig = this.dataSource;

          let embedUrl = accessConfig.embedUrl  
          let embedReportId = accessConfig.reportID;  
          let config = {  
              type: 'report',  
              accessToken: accessConfig.token,  
              embedUrl: embedUrl,  
              id: embedReportId,  
              settings: {}  
          };  
          let reportContainer = this.reportContainer.nativeElement;  
          let powerbi = new pbi.service.Service(pbi.factories.hpmFactory, pbi.factories.wpmpFactory, pbi.factories.routerFactory);  
          let report = powerbi.embed(reportContainer, config);  
          report.off("loaded");  
          report.on("loaded", () => {  
              console.log("Loaded");  
          });  
          report.on("error", () => {  
              console.log ("Error in powerbi loading...");  
          });  


      },
      (error) => console.log(error)
    );
  
}  






    constructor(private service: AuditService, private router: Router , private window: Window) {
  
    }

    ngOnInit() {

     this.screenHeight = (window.screen.height *0.9);  
     this.showReport(this.dataSource);
/*
      this.service.getAudits(9).subscribe(config => {
          
        console.log('ngOnInit....')
        this.config = config;
           console.log (window.name);
          const model =  window['powerbi-client'].models;
          const embedConfig = {
              type: 'report',
              tokenType: model.TokenType.Embed,
              accessToken: config.token,
              embedUrl: config.embedURL,
              permissions: model.Permissions.All,
              settings: {
                  filterPaneEnabled: true,
                  navContentPaneEnabled: true
              }
          };
          powerbi.embed(this.embeddedReport.nativeElement, embedConfig);
      });

      */
  }

    navigateTo  (url: string,id: any){

      this.router.navigate([url,id]);
    }

}
