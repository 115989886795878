import { Component } from '@angular/core';
import { DxDataGridModule,
  DxBulletModule,
  DxTemplateModule,DxSelectBoxModule, DxButtonModule,DxTreeListModule } from 'devextreme-angular';
import DataSource from 'devextreme/data/data_source';
import { AuditService } from '../../shared/services/audit.service'
import { Item,AuditResponse } from '../../shared/services/audit.service'
import {Router} from '@angular/router';


@Component({
  templateUrl: 'audit-tree.component.html',
  providers: [AuditService]
})

export class AuditTreeComponent {


  treeData: Item[];

  constructor(service: AuditService) {
    this.treeData = service.getComplianceTree();
    console.log(this.treeData);

  }

  getCompletionText(cellInfo) {
    return cellInfo.valueText + "%";
  }
}
