import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import {  throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class UserService {

//  private apiServer = "http://localhost:8010/proxy/users/";
//  private apiServer = "https://devspg.greenstreets.ie/users/";
  private apiServer = "https://devspg.greenstreets.ie/users/";
//private apiServer = "https://localhost/devspg/users/";

  constructor(private httpClient: HttpClient) { }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }

  login (credentials): Observable<any> {

    console.log('User Service...');
    console.log(JSON.stringify(credentials));
    return this.httpClient.post<any>(this.apiServer + 'login', JSON.stringify(credentials), this.httpOptions)
    .pipe(
      catchError(this.errorHandler))

  }

  errorHandler(error) {
    let errorMessage = '';
    if(error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
 }

}