
<h2 class="content-block">Saved Recycling Assessments</h2>

<dx-data-grid id="gridContainer" class="dx-card wide-card"
    [dataSource]="dataSource"
    [remoteOperations]="false"
    [allowColumnReordering]="true"
    [rowAlternationEnabled]="true"
    [showBorders]="false"
    keyExpr="id"
    [hoverStateEnabled]="true"
    [selectedRowKeys]="[]"

    [focusedRowEnabled]="true"
    [focusedRowIndex]="0"
    [columnAutoWidth]="true"
    [columnHidingEnabled]="true"

    (onContentReady)="contentReady($event)"
    (onRowClick)="rowClicked($event)"
    (onCellDblClick)="cellDblClicked($event)">
    <dxo-editing
        mode="cell"
        [allowAdding]="true"
        [allowUpdating]="true"
        [allowDeleting]="true">
    </dxo-editing>

    <dxo-selection mode="single"></dxo-selection>
    <dxi-column dataField="id" caption="ID#" ></dxi-column>
    <dxi-column dataField="audit_question_category_type_id" caption="Type ID#" >
        <dxo-lookup [dataSource]="categoriesData" valueExpr="id" displayExpr="name"></dxo-lookup>
    </dxi-column>
    <dxi-column dataField="shortened_description" caption="Short Desc." ></dxi-column>
    <dxi-column dataField="audit_date" caption="Date" dataType="date"></dxi-column>

</dx-data-grid>
