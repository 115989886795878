<ng-container *ngIf="isAutorized(); else notAuthorized">
  <div class="login-div">
  <app-side-nav-outer-toolbar title="{{appInfo.title}}">
    <router-outlet></router-outlet>
    <app-footer>

      <span><img src="/app/webroot/dist/gripsrecylx/assets/img/grips-logo.png" width="111px" height="60px"></span>
      Copyright © 2011-{{appInfo.currentYear}} {{appInfo.company}} Inc.
      <br/>
      All trademarks or registered trademarks are property of their respective owners.
    </app-footer>
  </app-side-nav-outer-toolbar>
</div>
</ng-container>

<ng-template #notAuthorized>
  
      <app-not-authorized-container></app-not-authorized-container>
  
</ng-template>
