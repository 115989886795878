export const navigation = [
  {
    text: 'Home',
    path: '/home',
    icon: 'home'
  },
  {
    text: 'Dashboards',
    icon: 'folder',
    items: [

        { text: 'Sustainable Packaging',
          path: '/reports'}

    ]
  }
/*
  {
    text: 'Examples',
    icon: 'folder',
    items: [
      {
        text: 'Profile',
        path: '/profile'
      },
      {
        text: 'Tasks',
        path: '/tasks'
      } , 
      {
        text: 'New Assessment',
        path: '/audit/250'
      },
         {
        text: 'Previous Assessment',
        path: '/audits'
      }
      ,
      {
     text: 'Recyclability Model',
     path: '/audittree'
   }

    ]
  }*/
];
