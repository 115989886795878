<div class="dx-card responsive-paddings">
    <div style=" width: 100%;" [ngStyle]="{ 'height': (screenHeight-150)+ 'px' }" #reportContainer></div> 
    
    <div class="logos-container">
     <!--<p><img src="/app/webroot/dist/gripsrecylx/assets/img/grips-logo.png"/></p> -->
    <img src="/app/webroot/dist/gripsrecylx/assets/img/spacer.png"/>
    
    </div>
</div>  


