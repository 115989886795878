<div class="content-block" width="100%">

    <div class="content-block dx-card responsive-paddings"  >

      <span *ngIf="auditResponse.Reference; else elseBlock">Reference::{{auditResponse.Reference}}</span>
      <ng-template #elseBlock><span>Reference::Unnamed Assessment</span></ng-template>

      <img src="/app/webroot/dist/gripsrecylx/assets/img/spacer.png"/>  
      <br/>
      <div class="form-avatar" >
          
          <img *ngIf="auditResponse.PackagingFormat.Image.length>0; else noImageBlock" src="{{imageRoot+auditResponse.PackagingFormat.Image}}"/>
          <ng-template #noImageBlock><img src="/app/webroot/dist/gripsrecylx/assets/img/unknown.png"/></ng-template>
          
        </div>
        
        <p>
          <span>Main Material Group: {{auditResponse.MainMaterial.Name}}</span><br/>
          <span>Packaging Format: {{auditResponse.PackagingFormat.Name}}</span><br/>
          <span>Sub Material Group: {{auditResponse.SubMaterial.Name}}</span>
        </p>
      

        <div *ngIf="assessed">
              <dx-linear-gauge
              id="gauge"
              
              [value]="recyclabilityScore">
                <dxo-size
                  [height]="300"
                  [width]="800">
                </dxo-size>
                  <dxo-value-indicator
                          type="textCloud"
                          color="{{recyclabilityColor}}">
                      </dxo-value-indicator>
                  <dxo-range-container [offset]="10">
                      <dxi-range [startValue]="0" [endValue]="51" color="#92000A"></dxi-range>
                      <dxi-range [startValue]="51" [endValue]="91" color="#E6E200"></dxi-range>
                      <dxi-range [startValue]="91" [endValue]="100" color="#77DD77"></dxi-range>
                  </dxo-range-container>
                  <dxo-scale
                      [startValue]="0"
                      [endValue]="100"
                      [tickInterval]="20">
                      <dxo-tick color="#536878"></dxo-tick>
                      <dxo-label [indentFromTick]="-3"></dxo-label>
                  </dxo-scale>
                  <dxo-export [enabled]="false"></dxo-export>
                  <dxo-title text="{{recyclableText}}">
                      <dxo-font [size]="24"></dxo-font>
                  </dxo-title>
          </dx-linear-gauge>
          <p></p>

        </div>
        
        <!--
          <span>
        <div *ngIf="assessedRecyclable">
          <dx-button
              stylingMode="contained"
              text="{{recyclableText}}"
              type="success"
              [width]="250"
              >
          </dx-button>
        </div>
        <div *ngIf="assessedNonRecyclable">
          <dx-button
              stylingMode="contained"
              text="{{recyclableText}}"
              type="danger"
              [width]="250"
              >
          </dx-button>
        </div>
      </span> -->
    </div>

    <div class="content-block dx-card responsive-paddings" >
      <aw-wizard #wizard [navBarLocation]="'left'" [navBarLayout]="'large-filled-symbols'">
        <aw-wizard-step stepTitle="Article Reference" [navigationSymbol]="{ symbol: '1' }" >
          <div class="content-block dx-card responsive-paddings" >          
                <p>Please enter a reference for the assessment:</p>
                  <dx-text-box  [(ngModel)]="refInput" (ngModelChange)="onKey($event)"></dx-text-box>
          </div>  
        <div class="content-block dx-card responsive-paddings" >
            <dx-button stylingMode="contained" type="canExitStep1() ? 'success' : 'default'" awNextStep>Next</dx-button>
        </div>
        </aw-wizard-step>

        <aw-wizard-step stepTitle="Main Material" [navigationSymbol]="{ symbol: '2' }">
          <div class="content-block dx-card responsive-paddings" >
              Please select the main material of the packaging:
              <dx-select-box 
                  [id]="'mainMaterial'"
                  [dataSource]="mainMaterialSource"
                  valueExpr="ID"
                  displayExpr="Name"
                  [searchEnabled]="true"
                  (onValueChanged)="onValueChanged($event)"
                  >
              </dx-select-box>
          </div>  
        <div class="content-block dx-card responsive-paddings" >
            <dx-button  awNextStep>Next</dx-button>
        </div>
        </aw-wizard-step>
        <aw-wizard-step stepTitle="Packaging Format" [navigationSymbol]="{ symbol: '3' }">
    
          <div class="content-block dx-card responsive-paddings" >
            Please select the format of the packaging:
            <dx-select-box 
                  [id]="'packagingFormat'"
                  [dataSource]="packFormatSource"
                  displayExpr="Name"
                  valueExpr="ID"
                  [grouped]="true"
                  (onValueChanged)="onValueChanged($event)"
                  [searchEnabled]="true"
                  groupTemplate="group">
                  <div *dxTemplate="let data of 'group'">
                      <div class="custom-icon">
                          <span class="dx-icon-box icon"></span>
                          {{data.key}}
                      </div>
                    </div>
            </dx-select-box>
        </div>  

          <div class="content-block dx-card responsive-paddings" >
              <dx-button  awPreviousStep>Previous</dx-button>
              <dx-button  awNextStep>Next</dx-button>
          </div>
        </aw-wizard-step>

        <aw-wizard-step stepTitle="Sub Material" [navigationSymbol]="{ symbol: '4' }">
            <div class="content-block dx-card responsive-paddings" >
              Please select the sub material of the packaging:
              <dx-select-box 
                    [id]="'subMaterial'"
                    [dataSource]="subMaterialSource"
                    displayExpr="Name"
                    valueExpr="ID"
                    (onValueChanged)="onValueChanged($event)"
                >
            </dx-select-box>
          </div>  
          <div class="content-block dx-card responsive-paddings" >
              <dx-button  awPreviousStep>Previous</dx-button>
              <dx-button  awNextStep>Next</dx-button>
          </div>
        </aw-wizard-step>
        <aw-wizard-step stepTitle="Variances" [navigationSymbol]="{ symbol: '5' }">
            <div class="content-block dx-card responsive-paddings" >
                Does the packaging contain any of the following variants:
                <dx-list
                height="300"
                [dataSource]="limitationSource"
                [selectionMode]="'multiple'"
                [showSelectionControls]="true"
                itemTemplate="data"
                [selectedItemKeys]="[]"
                (onSelectionChanged)="onSelectionChanged($event)"
                >
                <div *dxTemplate="let item of 'data'">
                    <div>{{item.Name}}</div>
                </div>
            </dx-list>
            </div>
      
            <div class="content-block dx-card responsive-paddings" >
              <dx-button  awPreviousStep>Previous</dx-button>
              <dx-button  (click)="finishFunction()">Calculate</dx-button>
              <dx-button  awResetWizard (finalize)="finalizeReset()">Reset</dx-button>
          </div>
        </aw-wizard-step>
      </aw-wizard>
    </div>
</div>
