
<!--<dxi-item
[editorType]=responseItem.editorType
[dataField]= responseItem.dataField
[label]= "'{text: '+ responseItem.label.text +'}'"
editorOptions= "{ responseItem.editorOptions }"
></dxi-item>-->
<label for="{{responseItem.dataField}}">{{responseItem.label.text}}</label>
<input name="{{responseItem.dataField}}" type="text" ngModel/>
<br/>

