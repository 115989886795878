import { Component, OnInit, Input, ViewContainerRef, ComponentFactoryResolver } from '@angular/core';
import { CommonModule } from '@angular/common';
import {Router, ActivatedRoute} from '@angular/router';
import { DxFormComponent} from 'devextreme-angular';
import { ResponseItemComponent } from './response-item.component';

@Component({
  selector: 'app-repsonse-item-container',
  templateUrl: 'response-item-container.component.html',
  styleUrls: ['response-item-container.component.scss']
})
export class ResponseMemberComponent implements OnInit {
  @Input() responseItem: any;

  constructor(
    private viewContainerRef: ViewContainerRef,
    private componentFactoryResolver: ComponentFactoryResolver,
  ) { 
    console.log('responseitemcontainer.ts');

  }

  ngOnInit(): void {
    this.viewContainerRef.clear();
    const rsItem = this.responseItem ;
      const responseComponentFactory = this.componentFactoryResolver.resolveComponentFactory(ResponseItemComponent);
      const componentRef = this.viewContainerRef.createComponent(responseComponentFactory);
      componentRef.instance.responseItem = rsItem;
  }
}