import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot } from '@angular/router';
import {SessionStorageService} from './session.storage.service';

import {UserService} from './user.service';
import {AuditService} from './audit.service';



const defaultPath = '/';
const spgPath = 'https://devspg.greenstreets.ie/users/';
const defaultUser = {
  email: 'sandra@example.com',
  avatarUrl: '/app/webroot/dist/gripsrecylx/assets/img/usericon.png'
};



@Injectable()
export class AuthService {
//  private _user = defaultUser;
  private _user = null;
  private _currentUser = {email: '',avatarUrl: '',role:{}};
  private session = new SessionStorageService();
  get loggedIn(): boolean {
    
    var blnIsLoggedIn: boolean = false;
    if (this.session.get('currentUser') === null) {
//      this._currentUser = { username: '', role: userRoles.public };
      this._currentUser = { email: '', avatarUrl:'', role:{} };
     
    } else {
      //this._currentUser =JSON.parse(this.session.get('currentUser'));
      this._currentUser =this.session.getObject('currentUser');
      if (this._currentUser.email.length>0) {
        blnIsLoggedIn = true;
      }
  }
  
  // return !!this._currentUser;
//    return !!this._user;
    return blnIsLoggedIn;
  }

  private _lastAuthenticatedPath: string = defaultPath;
  set lastAuthenticatedPath(value: string) {
    this._lastAuthenticatedPath = value;
  }

  constructor(private router: Router, private userService: UserService, private auditService: AuditService) { }

//  async logIn(email: string, password: string) {
 async logIn(email: string, password: string) {

    try {
      // Send request
      console.log(email, password);
      this._user = { ...defaultUser, email };

      this.userService.login ({username: email, password: password})
      .subscribe(
        (response) => {console.log(response);
                    console.log(new Date() + '=============response============');
                    console.log(response);
                      this._currentUser.email = response.username;
                      this._currentUser.role = response.role;
                      console.log(new Date() + '=============currentUser============');
                      console.log(this._currentUser);
                      this.session.setObject('currentUser',this._currentUser);
                      console.log(new Date() + '::  ========trying to login to /home==========');
                      this.router.navigate(['/home']);
        },
        (error) => console.log(error)
      );
  
      this.session.setObject('currentUser',this._currentUser);
        
        //to do fix to go to last authenticated path


      return {
        isOk: true,
        data: this._currentUser
      };
    }
    catch {
      return {
        isOk: false,
        message: "Authentication failed"
      };
    }
  }

  async getUser() {
    try {
      // Send request

      return {
        isOk: true,
        data: this._currentUser
      };
    }
    catch {
      return {
        isOk: false
      };
    }
  }

  async createAccount(email, password) {
    try {
      // Send request
      console.log(email, password);

      this.router.navigate(['/create-account']);
      return {
        isOk: true
      };
    }
    catch {
      return {
        isOk: false,
        message: "Failed to create account"
      };
    }
  }

  async changePassword(email: string, recoveryCode: string) {
    try {
      // Send request
      console.log(email, recoveryCode);

      return {
        isOk: true
      };
    }
    catch {
      return {
        isOk: false,
        message: "Failed to change password"
      }
    };
  }

  async resetPassword(email: string) {
    try {
      // Send request
      console.log(email);

      return {
        isOk: true
      };
    }
    catch {
      return {
        isOk: false,
        message: "Failed to reset password"
      };
    }
  }

  async logOut() {
    this._currentUser = {email: '',avatarUrl: '',role:{}};;
    this.session.setObject('currentUser',this._currentUser);
    this.router.navigate(['/login-form']);
  }
}

@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(private router: Router, private authService: AuthService) { }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const isLoggedIn = this.authService.loggedIn;
    const isAuthForm = [
      'login-form',
      'reset-password',
      'create-account',
      'change-password/:recoveryCode'
    ].includes(route.routeConfig.path);

    if (isLoggedIn && isAuthForm) {
      console.log(new Date() + ':: ========IsLoggedIn && IsAuthForm...' +route.routeConfig.path);
      console.log(defaultPath);
      this.authService.lastAuthenticatedPath = defaultPath;


      this.router.navigate([defaultPath]);

      return false;
    }

    if (!isLoggedIn && !isAuthForm) {
      console.log(new Date() + ':: ===========not logged in and not an authForm');
      this.router.navigate(['/login-form']);
    }

    if (isLoggedIn) {
      console.log(new Date() + ':: =====AuthGuardService===canActivate=====isLoggedIn');
      console.log(route.routeConfig.path);
      if (route.routeConfig.path===defaultPath || route.routeConfig.path==='/login-form'){
        this.authService.lastAuthenticatedPath = '/home';
      }else{
      this.authService.lastAuthenticatedPath = route.routeConfig.path;}
    }

    return isLoggedIn || isAuthForm;
  }
}



